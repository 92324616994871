






















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavigationDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  navigate(target: string) {
    this.$router.push({ name: target });
    this.$emit('update-drawer-state', false);
  }

  get hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights;
  }

  test() {}
}
