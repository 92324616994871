<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="selectedHeaders"
          :items="headers.filter(x => !x.isAction)"
          label="Angezeigte Spalten"
          @change="changeHeaders"
          multiple
          outlined
          return-object
          show-select
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 5" class="grey--text text-caption">(+{{ selectedHeaders.length - 5 }} weitere)</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                style="background-color: lime"
                v-model.number="totalSetupTime"
                type="number"
                label="Rüstzeit Gesamt [min]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn @click="defineSetupTimeOverall">Rüstzeit aufteilen</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="showHeaders"
          :items="items"
          class="elevation-1"
          group-by="source_file_uuid"
          :expanded.sync="expanded"
          item-key="part_uuid"
          disable-pagination
          hide-default-footer
          single-expand
        >
          <template v-slot:body.prepend="{ items }">
            <tr style="background-color: lightblue">
              <td v-if="displayColumn('selector')">
                <v-checkbox @change="toggleSelectAll(items)" v-model="selectedAll"></v-checkbox>
              </td>
              <td v-if="displayColumn('name')">
                <v-text-field @change="changeTemplateValue('name', $event, false, false)"></v-text-field>
              </td>
              <td v-if="displayColumn('num_parts')">
                <v-text-field v-model="templateCount"></v-text-field>
              </td>
              <td v-if="displayColumn('material')">
                <v-select
                  @change="changeTemplateValue('material', $event, false, false)"
                  :items="[''].concat(materialItems)"
                ></v-select>
              </td>
              <td v-if="displayColumn('material_detail')">
                <v-text-field @change="changeTemplateValue('material_detail', $event, false, false)"></v-text-field>
              </td>
              <td v-if="displayColumn('form')">
                <v-select
                  @change="changeTemplateValue('form', $event, false, false)"
                  :items="[''].concat(formItems)"
                ></v-select>
              </td>
              <td v-if="displayColumn('long_side')">
                <v-text-field
                  @change="changeTemplateValue('long_side', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('short_side')">
                <v-text-field
                  @change="changeTemplateValue('short_side', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('thickness')">
                <v-text-field
                  @change="changeTemplateValue('thickness', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('length')">
                <v-text-field @change="changeTemplateValue('length', $event, true, false)" type="number"></v-text-field>
              </td>
              <td v-if="displayColumn('drill_count')">
                <v-text-field
                  @change="changeTemplateValue('drill_count', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('drill_avg_diam')">
                <v-text-field
                  @change="changeTemplateValue('drill_avg_diam', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('part_cutting_length_total')">
                <v-text-field
                  @change="changeTemplateValue('part_cutting_length_total', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cutting_length_total')">
                <v-text-field
                  @change="changeTemplateValue('cutting_length_total', $event, true, false)"
                  type="number"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('share_operator')">
                <v-text-field
                  @change="changeTemplateValue('share_operator', $event, true, false)"
                  type="number"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('complexity_programming')">
                <v-select
                  @change="changeTemplateValue('complexity_programming', $event, false, false)"
                  :items="[''].concat(programmingItems)"
                ></v-select>
              </td>
              <td v-if="displayColumn('technic_text')">
                <v-text-field @change="changeTemplateValue('technic_text', $event, false, false)"></v-text-field>
              </td>
              <td v-if="displayColumn('setup_time')">
                <v-text-field
                  @change="changeTemplateValue('setup_time', $event, true, true)"
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <v-btn @click="applyToSelected" fab text small>
                  <v-icon>
                    save_as
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:item="{ item, isExpanded, expand }">
            <tr :style="errorStyle(setOfPart(item.part_uuid).calculation)">
              <td v-if="displayColumn('selector')">
                <v-checkbox
                  :value="isRowSelected(item.part_uuid)"
                  @change="switchSelectRow(item.part_uuid)"
                ></v-checkbox>
              </td>
              <td v-if="displayColumn('name')">
                <v-text-field
                  :value="item.details.name"
                  @change="changeProperty(item.part_uuid, 'name', $event)"
                  :style="item.details.name_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('num_parts')">
                <v-text-field
                  type="number"
                  :value="setOfPart(item.part_uuid).parts[0].count"
                  @change="numSetChanged(Number($event), setOfPart(item.part_uuid).set_uuid, item.part_uuid, 0)"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('material')">
                <v-select
                  :items="materialItems"
                  :value="item.details.material ? item.details.material : materialItems[0]"
                  @change="changeProperty(item.part_uuid, 'material', $event)"
                  :style="item.details.material_modified ? 'background-color: aqua' : ''"
                ></v-select>
              </td>
              <td v-if="displayColumn('material_detail')">
                <v-text-field
                  :value="item.details.material_detail"
                  @change="changeProperty(item.part_uuid, 'material_detail', $event)"
                  :style="item.details.material_detail_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('form')">
                <v-select
                  :items="formItems"
                  :value="item.details.form"
                  @change="changeProperty(item.part_uuid, 'form', $event)"
                  :style="item.details.form_modified ? 'background-color: aqua' : ''"
                ></v-select>
              </td>
              <td v-if="displayColumn('long_side')">
                <v-text-field
                  type="number"
                  :value="item.details.long_side"
                  @change="changeProperty(item.part_uuid, 'long_side', Number($event))"
                  :style="item.details.long_side_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('short_side')">
                <v-text-field
                  type="number"
                  :value="item.details.short_side"
                  @change="changeProperty(item.part_uuid, 'short_side', Number($event))"
                  :style="item.details.short_side_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('thickness')">
                <v-text-field
                  type="number"
                  :value="item.details.thickness"
                  @change="changeProperty(item.part_uuid, 'thickness', Number($event))"
                  :style="item.details.thickness_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('length')">
                <v-text-field
                  type="number"
                  :value="item.details.length"
                  @change="changeProperty(item.part_uuid, 'length', Number($event))"
                  :style="item.details.length_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('drill_count')">
                <v-text-field
                  type="number"
                  :value="item.details.drill_count"
                  @change="changeProperty(item.part_uuid, 'drill_count', Number($event))"
                  :style="item.details.drill_count_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('drill_avg_diam')">
                <v-text-field
                  type="number"
                  :value="item.details.drill_avg_diam"
                  @change="changeProperty(item.part_uuid, 'drill_avg_diam', Number($event))"
                  :style="item.details.drill_avg_diam_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('part_cutting_length_total')">
                <v-text-field
                  type="number"
                  :value="item.details.part_cutting_length_total"
                  @change="changeProperty(item.part_uuid, 'part_cutting_length_total', Number($event))"
                  :style="item.details.part_cutting_length_total_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('cutting_length_total')">
                <v-text-field
                  type="number"
                  :value="item.details.part_cutting_length_total * setOfPart(item.part_uuid).parts[0].count"
                  readonly
                  disabled
                ></v-text-field>
              </td>
              <td v-if="displayColumn('share_operator')">
                <v-text-field
                  type="number"
                  :value="item.details.share_operator"
                  @change="changeProperty(item.part_uuid, 'share_operator', Number($event))"
                  :style="item.details.share_operator_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('complexity_programming')">
                <v-select
                  :items="programmingItems"
                  :value="item.details.complexity_programming"
                  @change="changeProperty(item.part_uuid, 'complexity_programming', $event)"
                  :style="item.details.complexity_programming_modified ? 'background-color: aqua' : ''"
                ></v-select>
              </td>
              <td v-if="displayColumn('technic_text')">
                <v-text-field
                  :value="item.details.technic_text"
                  @change="changeProperty(item.part_uuid, 'technic_text', $event)"
                  :style="item.details.technic_text_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td v-if="displayColumn('setup_time')">
                <v-text-field
                  type="number"
                  :value="item.details.setup_time / 60"
                  @change="changeProperty(item.part_uuid, 'setup_time', Number($event) * 60)"
                  :style="item.details.setup_time_modified ? 'background-color: aqua' : ''"
                ></v-text-field>
              </td>
              <td>
                <div class="d-flex justify-space-between">
                  <v-btn @click="expand(!isExpanded)" fab text small>
                    <v-icon v-if="!isExpanded">
                      expand_more
                    </v-icon>
                    <v-icon v-else>
                      expand_less
                    </v-icon>
                  </v-btn>
                  <v-btn @click="resetPart(item.part_uuid)" fab text small>
                    <v-icon>
                      refresh
                    </v-icon>
                  </v-btn>
                  <v-btn @click="deletePart(item.part_uuid)" fab text small>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:group.header="{ headers, items, isOpen, toggle }">
            <th :colspan="headers.length">
              <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
              {{ nameOfFileUUID(items[0].source_file_uuid) }}
            </th>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <v-row>
                  <v-col cols="4" style="border: thin dashed black;">
                    <GLTFViewer :partUUID="item.part_uuid" :key="item.part_uuid"></GLTFViewer>
                  </v-col>
                  <v-col>
                    <v-container>
                      <v-row>
                        <template v-if="setOfPart(item.part_uuid).calculation.cost_has_error_during_calc">
                          <v-col cols="12" style="font-weight: bold">Fehler-Meldungen</v-col>
                          <v-col cols="12">
                            <v-list-item
                              v-for="item in setOfPart(item.part_uuid).calculation.error_messages"
                              :key="item"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </template>
                        <template v-if="setOfPart(item.part_uuid).calculation.has_filter_messages">
                          <v-col cols="12" style="font-weight: bold">Filter-Meldungen</v-col>
                          <v-col cols="12">
                            <v-list-item
                              v-for="item in setOfPart(item.part_uuid).calculation.filter_messages"
                              :key="item"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </template>
                        <v-col cols="12" style="font-weight: bold">Gravur</v-col>
                        <v-col cols="12">
                          <v-textarea
                            auto-grow
                            rows="2"
                            :value="item.details.engraving_text"
                            label="Gravur-Text erfassen"
                            hint="Gravur-Text für Kostenberechnung eingeben"
                            @change="engravingChanged($event, item)"
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="12" style="font-weight: bold">Bohrungen / Löcher</v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <template v-if="item.details.drilled_holes && item.details.drilled_holes.length > 0">
                            <table class="tt">
                              <tr>
                                <th>Anzahl</th>
                                <th>Durchmesser [mm]</th>
                                <th>Gewinde erfassen</th>
                              </tr>
                              <template v-for="(value, ind) of item.details.drilled_holes">
                                <tr :key="`drill${ind}`">
                                  <td>{{ value.count }}</td>
                                  <td>{{ value.diameter }}</td>
                                  <td>
                                    <v-btn icon @click="addThread(value.count, null, item)">
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </template>
                          <div v-else>
                            Keine Bohrungen erkannt
                          </div>
                        </v-col>
                        <v-col cols="12" style="font-weight: bold">Gewinde</v-col>
                        <v-col cols="12">
                          <v-btn @click="addThread(null, null, item)">
                            <v-icon>
                              add
                            </v-icon>
                            Gewinde hinzufügen
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <template v-if="item.details.threads.length > 0">
                            <table class="tt">
                              <tr>
                                <th>Anzahl</th>
                                <th>Gewinde</th>
                                <th>Gewinde entfernen</th>
                              </tr>
                              <template v-for="(value, ind) of item.details.threads">
                                <tr :key="`thread${ind}`">
                                  <td>
                                    <v-text-field
                                      style="text-align: center"
                                      :value="value.count"
                                      class="thread-detail"
                                      @change="changeThread(Number($event), value.type, item, ind)"
                                      dense
                                    />
                                  </td>
                                  <td>
                                    <v-select
                                      :items="threads"
                                      :value="value.type"
                                      class="thread-detail-select"
                                      @change="changeThread(value.count, $event, item, ind)"
                                      dense
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-btn icon @click="deleteThread(item, ind)">
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </template>
                          <div v-else>
                            Keine Gewinde definiert
                          </div>
                        </v-col>
                        <v-col cols="12" style="font-weight: bold">Ausschnitte</v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <template v-if="item.details.cutouts_info && item.details.cutouts_info.length > 0">
                            <table class="tt">
                              <tr>
                                <th>Anzahl</th>
                                <th>Typ</th>
                                <th>Schnittlänge [mm]</th>
                                <th>Details</th>
                              </tr>
                              <template v-for="(value, ind) of item.details.cutouts_info">
                                <tr :key="`cutinfo${ind}`">
                                  <td>{{ value.count }}</td>
                                  <td>{{ value.type }}</td>
                                  <td>{{ value.cutting_length }}</td>
                                  <td>
                                    {{ value.detail }}
                                  </td>
                                </tr>
                              </template>
                            </table>
                          </template>
                          <div v-else>
                            Keine Ausschnitte erkannt
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import GLTFViewer from '@/components/util/GLTFViewer';
import Vue from 'vue';

export default {
  name: 'CalcParamPrototype',
  components: { GLTFViewer },
  data() {
    return {
      formItems: ['Rundrohr', 'Rechteckrohr', 'Quadratrohr', 'L-Profil', 'U-Profil', 'H-Profil', 'Flach', 'Gebogen'],
      programmingItems: ['einfach', 'mittel', 'kompliziert'],
      materialItems: ['Stahl', 'Alu', 'CNS'],
      selectedHeaders: [],
      expanded: [],
      selectedAll: false,
      selectedRows: [],
      templateValues: {},
      templateCount: '',
      threads: ['M4', 'M5', 'M6', 'M8', 'M10', 'F+G M4', 'F+G M5', 'F+G M6', 'F+G M8'],
      headersMap: {
        selector: { text: 'Selector', value: 'selector' },
        name: { text: 'Bezeichnung', align: 'start', sortable: false, value: 'name' },
        num_parts: { text: 'Anzahl', value: 'num_parts' },
        material: { text: 'Material', value: 'material' },
        material_detail: { text: 'Werkstoff', value: 'material_detail' },
        form: { text: 'Form', value: 'form' },
        long_side: { text: 'lange Seite (Ø)', value: 'long_side' },
        short_side: { text: 'kurze Seite', value: 'short_side' },
        thickness: { text: 'Dicke', value: 'thickness' },
        length: { text: 'Länge', value: 'length' },
        drill_count: { text: '# runde Löcher', value: 'drill_count' },
        drill_avg_diam: { text: 'Ø Durchmesser', value: 'drill_avg_diam' },
        part_cutting_length_total: { text: 'Schnittmeter Teil', value: 'part_cutting_length_total' },
        cutting_length_total: { text: 'Total Schnittmeter', value: 'cutting_length_total' },
        share_operator: { text: 'Anteil Bediener', value: 'share_operator' },
        complexity_programming: { text: 'Komplexität Prog.', value: 'complexity_programming' },
        technic_text: { text: 'Techniktext', value: 'technic_text' },
        setup_time: { text: 'Rüstzeit Teil [min]', value: 'setup_time' },
        expand_slot: { text: 'Aktionen', value: 'data-table-expand', isAction: true, sortable: false }
      },
      headers: [],
      totalSetupTime: 0,
      addingThread: false
    };
  },
  created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
    axios.post(`${BACKEND_URL}/get_user_preference`, { name: 'params_headers' }).then(res => {
      if (res.data.params_headers) {
        this.selectedHeaders = res.data.params_headers;
      }
    });
  },
  methods: {
    defineSetupTimeOverall() {
      axios
        .post(`${BACKEND_URL}/define_setup_time_overall`, {
          offer_id: this.$store.getters.currentOfferID,
          value: this.totalSetupTime
        })
        .then(response => {
          this.$store.dispatch('updateOfferComplete', response.data);
        });
    },
    numSetChanged(count, setUUID, partUUID, ind) {
      this.$store.dispatch('numSetChanged', { count, setUUID, partUUID, ind });
    },
    changeHeaders(val) {
      axios.post(`${BACKEND_URL}/update_user_preference`, { name: 'params_headers', value: val });
    },
    engravingChanged(val, item) {
      this.changeProperty(item.part_uuid, 'engraving_text', val);
    },
    addThread(count, type, item) {
      let threads = item.details.threads;
      threads.push({ count: count, type: type });
      this.changeProperty(item.part_uuid, 'threads', threads);
    },
    changeThread(count, type, item, ind) {
      let threads = item.details.threads;
      threads[ind] = { count: count, type: type };
      this.changeProperty(item.part_uuid, 'threads', threads);
    },
    deleteThread(item, ind) {
      let threads = item.details.threads;
      threads.splice(ind, 1);
      this.changeProperty(item.part_uuid, 'threads', threads);
    },
    displayColumn(columnName) {
      return this.selectedHeaders.some(obj => obj.value === columnName);
    },
    deletePart(part_uuid) {
      this.$store.dispatch('deletePart', part_uuid);
    },
    resetPart(part_uuid) {
      this.$store.dispatch('resetPartDetail', part_uuid);
    },

    changeProperty(part_uuid, name, value) {
      this.$store.dispatch('updatePartDetail', { part_uuid: part_uuid, name: name, value: value });
    },
    applyToSelected(part_uuid, name, value) {
      // TODO KEEP TRACK OF VALUES FROM TEMPLATE ROW

      if (this.templateCount.length > 0) {
        let part_and_set_uuids = [];
        for (let part_uuid of this.selectedRows) {
          part_and_set_uuids.push({ part_uuid: part_uuid, set_uuid: this.setOfPart(part_uuid).set_uuid });
        }
        this.$store
          .dispatch('numSetsChanged', {
            part_and_set_uuids: part_and_set_uuids,
            count: Number(this.templateCount)
          })
          .then(r => {
            this.$store.dispatch('updatePartDetailBatch', {
              part_uuids: this.selectedRows,
              values: this.templateValues
            });
          });
      } else {
        this.$store.dispatch('updatePartDetailBatch', {
          part_uuids: this.selectedRows,
          values: this.templateValues
        });
      }
    },
    nameOfFileUUID(uuid) {
      return this.$store.getters.getFileNameToFileUUID(uuid);
    },
    errorStyle(calculation) {
      let hasError = calculation.cost_has_error_during_calc;
      let hasFilterMessages = calculation.has_filter_messages;
      if (hasError) {
        return 'background-color: orange';
      } else if (hasFilterMessages) {
        return 'background-color: yellow';
      } else {
        return '';
      }
    },
    toggleSelectAll(items) {
      if (this.selectedAll) {
        for (let part_uuid of items.map(x => x.part_uuid)) {
          if (this.selectedRows.indexOf(part_uuid) < 0) {
            this.selectedRows.push(part_uuid);
          }
        }
      } else {
        this.selectedRows.splice(0, this.selectedRows.length);
      }
    },
    isRowSelected(partUUID) {
      return this.selectedRows.indexOf(partUUID) >= 0;
    },
    switchSelectRow(partUUID) {
      let index = this.selectedRows.indexOf(partUUID);
      if (index >= 0) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(partUUID);
      }
    },
    setOfPart(partUUID) {
      return this.$store.getters.getSingleSetOfPartUUID(partUUID);
    },
    changeTemplateValue(name, value, isNumeric, convertToSeconds) {
      if (value === '' || value.length === 0) {
        Vue.delete(this.templateValues, name);
      } else if (convertToSeconds) {
        this.templateValues[name] = Number(value) * 60;
      } else {
        this.templateValues[name] = isNumeric ? Number(value) : value;
      }
    }
  },
  computed: {
    items: function() {
      return Object.values(this.$store.getters.parts);
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.some(x => x.value === s.value) || s.isAction);
    }
  },
  mounted() {}
};
</script>

<style>
.thread-detail input {
  padding: 10px;
  text-align: center;
}

.thread-detail-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.tt {
  border: 1px solid black;
}

table.tt {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
}

table.tt td {
  border: 1px solid black;
}
</style>
