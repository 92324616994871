<template>
  <v-container>
    <v-row>
      <v-col cols="12" style="font-weight: bold">Stangenoptimierung</v-col>
      <v-col cols="3">
        <v-btn @click="addConfiguration">
          <v-icon>
            add
          </v-icon>
          Stangenkonfiguration hinzufügen
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn @click="optimize" class="light-green">
          Stangenoptimierung durchführen
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn @click="optimizeAndDownload" class="light-green">
          Stangenoptimierung Download
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <template>
          <table class="tt">
            <tr>
              <th>Konfiguration</th>
              <th>Stangenlänge [mm]</th>
              <th>Klemmlänge [mm]</th>
              <th>Schnittbreite [mm]</th>
              <th>Konfiguration entfernen</th>
            </tr>
            <template v-for="(value, ind) of rodConfigurations">
              <tr :key="`configs${ind}`">
                <td>{{ ind + 1 }}</td>
                <td>
                  <v-text-field
                    style="text-align: center"
                    :value="value.rod_length"
                    class="thread-detail"
                    @change="changeConfiguration(Number($event), value.clamp_length, value.cut_width, ind)"
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    style="text-align: center"
                    :value="value.clamp_length"
                    class="thread-detail"
                    @change="changeConfiguration(value.rod_length, Number($event), value.cut_width, ind)"
                    dense
                  />
                </td>
                <td>
                  <v-text-field
                    style="text-align: center"
                    :value="value.cut_width"
                    class="thread-detail"
                    @change="changeConfiguration(value.rod_length, value.clamp_length, Number($event), ind)"
                    dense
                  />
                </td>
                <td>
                  <v-btn icon @click="deleteConfiguration(ind)">
                    <v-icon>remove</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </table>
        </template>
      </v-col>
      <div v-if="loading">Berechnet Konfiguration ...</div>
      <template v-for="(res, ind) in optimizationResult">
        <v-col cols="12" :key="`titel_${ind}`"> Konfiguration {{ ind + 1 }} Resultat </v-col>
        <v-col cols="12" :key="`table_${ind}`">
          <v-data-table :headers="headers" :items="res" disable-pagination hide-default-footer></v-data-table>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'CalcRodOptimization',
  data() {
    return {
      loading: false,
      rodConfigurations: [{ rod_length: 6000, clamp_length: 300, cut_width: 4 }],
      headers: [
        { text: 'Form', align: 'start', sortable: false, value: 'form' },
        { text: 'Material', value: 'material' },
        { text: 'Werkstoff', value: 'material_detail' },
        { text: 'Techniktext', value: 'technic_text' },
        { text: 'lange Seite (Ø)', value: 'long_side' },
        { text: 'kurze Seite', value: 'short_side' },
        { text: 'Wandstärke', value: 'thickness' },
        { text: '# Stangen', value: 'num_rods' },
        { text: 'Verschnitt (letzte Stange)', value: 'rest' },
        { text: 'Fehler', value: 'error' }
      ]
    };
  },
  methods: {
    optimize() {
      this.$store.commit('setOptimizationResult', {});
      this.loading = true;
      axios
        .post(`${BACKEND_URL}/rod_optimization`, {
          offer_id: this.$store.getters.currentOfferID,
          configurations: this.rodConfigurations
        })
        .then(res => {
          this.$store.commit('setOptimizationResult', res.data.result);
          this.loading = false;
        });
    },
    optimizeAndDownload() {
      axios
        .post(
          `${BACKEND_URL}/rod_optimization_download`,
          {
            offer_id: this.$store.getters.currentOfferID,
            configurations: this.rodConfigurations
          },
          { responseType: 'blob' }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Offerte_${this.$store.getters.currentOfferID}_Stangenoptimierung.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    },
    changeHeaders(val) {
      axios.post(`${BACKEND_URL}/update_user_preference`, { name: 'params_headers', value: val });
    },
    engravingChanged(val, item) {
      this.changeProperty(item.part_uuid, 'engraving_text', val);
    },
    addConfiguration() {
      this.rodConfigurations.push({ rod_length: null, clamp_length: 300, cut_width: 4 });
    },
    changeConfiguration(rod_length, clamp_length, cut_width, ind) {
      this.rodConfigurations[ind] = { rod_length: rod_length, clamp_length: clamp_length, cut_width: cut_width };
    },
    deleteConfiguration(ind) {
      this.rodConfigurations.splice(ind, 1);
    },
    displayColumn(columnName) {
      return this.selectedHeaders.some(obj => obj.value === columnName);
    },
    nameOfFileUUID(uuid) {
      return this.$store.getters.getFileNameToFileUUID(uuid);
    },
    errorStyle(value) {
      let hasError = 'error' in value;
      if (hasError) {
        return 'background-color: orange';
      } else {
        return '';
      }
    }
  },
  computed: {
    items: function() {
      return Object.values(this.$store.getters.parts);
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.some(x => x.value === s.value) || s.isAction);
    },
    optimizationResult() {
      return this.$store.getters.optimizationResult;
    }
  },
  mounted() {}
};
</script>

<style>
.thread-detail input {
  padding: 10px;
  text-align: center;
}

.thread-detail-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

td.tt {
  border: 1px solid black;
}

table.tt {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
}

table.tt td {
  border: 1px solid black;
}
</style>
