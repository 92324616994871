
































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CalcFileUpload from '../components/calculator/CalcFileUpload.vue';
import CalcProperties from '@/components/calculator/CalcProperties.vue';
import CalcOffer from '@/components/calculator/CalcOffer.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcShipping from '@/components/calculator/CalcShipping.vue';
import CalcOrder from '@/components/calculator/CalcOrder.vue';
import CalcExportLastech from '@/components/calculator/CalcExportLastech.vue';
import CalcParamPrototype from '@/components/calculator/CalcParamPrototype.vue';
import CalcParamPrototypeCost from '@/components/calculator/CalcParamPrototypeCost.vue';
import CalcRodOptimization from '@/components/calculator/CalcRodOptimization.vue';

interface SelectedCustomersTypes {
  customer_id: number;
  sub_customer_id: number;
}

@Component({
  components: {
    calcfileupload: CalcFileUpload,
    calcproperties: CalcProperties,
    calcoffer: CalcOffer,
    calcshipping: CalcShipping,
    calcorder: CalcOrder,
    calcexport: CalcExportLastech,
    calcparamprototype: CalcParamPrototype,
    calcparamprototypecost: CalcParamPrototypeCost,
    calcrodoptimization: CalcRodOptimization
  }
})
export default class Calculator extends Vue {
  offer_name: string = '';
  offset: string = '';
  steps = this.defineSteps();
  internOffer: boolean = false;

  defineSteps() {
    let vm = this;

    let steps = [];

    steps.push({
      name: 'Dateien hochladen',
      component: 'calcfileupload',
      eventHandling: {}
    });
    steps.push({
      name: 'Parameter-Extraktion',
      component: 'calcparamprototype',
      eventHandling: {}
    });
    steps.push({
      name: 'Kostenübersicht',
      component: 'calcparamprototypecost',
      eventHandling: {}
    });
    steps.push({
      name: 'Stangenoptimierung',
      component: 'calcrodoptimization',
      eventHandling: {}
    });
    // steps.push({
    //   name: 'Stangenoptimierung',
    //   component: 'calcproperties',
    //   eventHandling: {}
    // });
    // steps.push({
    //   name: 'Offerte',
    //   component: 'calcoffer',
    //   eventHandling: {}
    // });

    // if (this.hasManufacturerRights()) {
    //   steps.push({
    //     name: 'Export',
    //     component: 'calcexport',
    //     eventHandling: {}
    //   });
    // }

    return steps;
  }

  backToOfferOverview() {
    this.$store.commit('resetOffer');
    this.$router.push({ path: '/' });
  }

  deleteOffer() {
    this.$store.dispatch('deleteCurrentOffer');
    this.$store.commit('resetOffer');
  }

  get currentOfferName() {
    return this.$store.getters.getFormattedOfferId + ' - ' + this.$store.getters.currentOfferName;
  }

  get isNavDrawerOpen() {
    return this.$store.getters.isNavDrawerOpen;
  }

  @Watch('isNavDrawerOpen')
  onNavDrawerChange(val: boolean, old_val: boolean) {
    if (val) {
      this.offset = `left:${this.calcOffset()}px`;
    } else {
      this.offset = '';
    }
  }

  get currentStep() {
    return this.$store.getters.currentStepCalcDialog;
  }

  calcOffset() {
    let calc_container: Element = this.$refs.calc_container as Element;
    return calc_container.getBoundingClientRect().left;
  }

  updateStep(step_number: number) {
    this.$store.commit('setCurrentStepCalcDialog', step_number);
  }

  get isOfferActive() {
    return this.$store.getters.isOfferActive;
  }

  createOffer() {
    axios
      .post(`${BACKEND_URL}/create_offer`, {
        offer_name: this.offer_name,
        is_intern: this.internOffer
      })
      .then(response => {
        if (response.status === 200) {
          if ('offer' in response.data) {
            this.$store.commit('setOffer', response.data.offer);
          }
        }
      });
  }

  isCustomer() {
    return this.$store.getters.isCustomer;
  }

  hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights;
  }

  mounted() {
    if (this.isNavDrawerOpen) {
      this.offset = `left:${this.calcOffset()}px`;
    }
  }
}
