












































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import { formatOfferId } from '@/store';

export default Vue.extend({
  data: () => ({
    offers: [],
    headers: [
      {
        text: 'Nummer',
        align: 'left',
        sortable: false,
        value: 'offer_id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Benutzer', value: 'user' },
      { text: 'Datum', value: 'date' },
      { text: 'Status', value: 'status' },
      { text: 'Aktionen', value: 'action', sortable: false }
    ]
  }),
  created() {
    this.retrieveOffers();
  },
  computed: {
    getOffers() {
      let offers = this.offers.filter((offer: any) => !offer.status.startsWith('Trainer'));

      const options = {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
      };
      for (let o of offers) {
        let oo: any = o;
        oo.date = new Date(oo.date).toLocaleDateString('de-CH', options);
      }
      return offers;
    },

    hasManufacturerRights() {
      return this.$store.getters.hasManufacturerRights;
    }
  },
  methods: {
    pdf(offerId: number) {
      axios
        .post(`${BACKEND_URL}/pdf`, { offer_id: offerId }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${offerId}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {});
    },
    deleteItem(offerId: number) {
      axios
        .post(`${BACKEND_URL}/delete_offer`, { offer_id: offerId })
        .then(response => {
          this.retrieveOffers();
        })
        .catch(error => {});
    },
    editItem(offerId: any) {
      this.$store.dispatch('loadOffer', offerId);
    },
    download(offerId: number) {
      axios.post(`${BACKEND_URL}/download_parts`, { offer_id: offerId }, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${offerId}.zip`);
        document.body.appendChild(link);
        link.click();
      });
    },
    retrieveOffers() {
      axios
        .get(`${BACKEND_URL}/offers`)
        .then(response => {
          if (response.status === 200) {
            this.offers = response.data;
          }
        })
        .catch(error => {});
    },
    newOffer() {
      this.$store.commit('resetOffer');
      this.$router.push('/calc');
    }
  }
});
